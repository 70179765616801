import React, { Component } from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";
import Livestream from "../components/livestream";
import Modal from "../components/modal";
import Banner from "../components/banner";

class IndexPage extends Component {
  render() {
    return (
      <Layout>
        <SEO title="HOME" keywords={[`jeremy scott`, "fashion designer"]} />
        {/* <Banner
          display={true}
          headline={`Jeremy Scott SS20 Runway Live<br/>Tonight at 8PM EST`}
        /> */}
        {/* <Modal className="livestream">
          <h2>Jeremy Scott SS20 Runway Live</h2>
          <Livestream />
        </Modal> */}
      </Layout>
    );
  }
}

export default IndexPage;
