import React from "react";
import "./livestream.scss";
// const Livestream = ({ headline, subHeadline, display }) => {

class Livestream extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iframeHeight: 0
    };
    this.liveStreamWrapper = React.createRef();
  }
  componentDidMount() {
    this.setIframeHeight();
    window.addEventListener("resize", this.setIframeHeight);
  }
  setIframeHeight = () => {
    // let modalContentContainer = document.querySelector(".modal__content");
    let iframeHeight = this.liveStreamWrapper.current.offsetWidth / (4 / 2.3);
    this.setState({ iframeHeight: iframeHeight });
  };
  render() {
    return (
      <div
        className="blive-responsive-iframe-wrapper"
        ref={this.liveStreamWrapper}
      >
        <iframe
          id="ls_embed_1566843023"
          src="https://livestream.com/accounts/19186937/events/8796685/player?width=640&height=360&enableInfoAndActivity=true&defaultDrawer=&autoPlay=true&mute=false"
          width="100%"
          height={this.state.iframeHeight}
          frameBorder="0"
          scrolling="no"
          allowFullScreen
        />
        <script
          type="text/javascript"
          data-embed_id="ls_embed_1566843023"
          src="https://livestream.com/assets/plugins/referrer_tracking.js"
        />
      </div>
    );
  }
}
export default Livestream;
